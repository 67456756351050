import React, { useEffect, useState } from "react";
import FacilityReportAttachedIcon from "../shared/components/FacilityReportAttachedIcon";
import FileFordwardedIcon from "../shared/components/FileFordwardedIcon";
import moment from "moment";
import { HeaderNotesList } from "../notes/HeaderNotesList";
import * as Enums from "../../commons/Enums";
import ConfirmationPageFile from "./ConfirmationPageFile";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import { useHighlightIcon } from "hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import TimeCardAdjusmentsIcon from "../shared/components/TimeCardAdjusmentsIcon";
import TimecardIsLateIcon from "../shared/components/TimecardIsLateIcon";
import TimecardFileIcon from "../shared/components/TimecardFileIcon";
import PayrollApi from "api/PayrollApi";
import { usePermissions } from "hooks";
import { TimesheetStatus } from "../../commons/Enums";
import HightlightIcon from "components/shared/components/HighlightIcon";
import SignatureIcon from "components/shared/components/SignatureIcon";
import TimecardDetailTopbarCallOffs from "./TimecardDetailTopbarCallOffs";
import AdpCodeInput from "./AdpCodeInput";
import UserNotifications from "./UserNotifications";

type TimecardDetailTopbarProps = {
  jobAssignment: any;
  onConfirmationPageFileUploaded: () => void;
  displayIcons: boolean;
  isReadOnly: boolean;
  fetchAssignments: () => void;
  onMessagesRead: () => void;
  onAdpCodeUpdated: (adpCode: string | null) => void;
};

const shouldOpenPDF = (status: string) => {
  switch (status) {
    case TimesheetStatus.DRAFT:
    case TimesheetStatus.REJECTED:
    case TimesheetStatus.RETURNED:
      return true;
    default:
      return false;
  }
};

const TimecardDetailTopbar = ({
  jobAssignment,
  onConfirmationPageFileUploaded,
  displayIcons,
  isReadOnly,
  fetchAssignments,
  onMessagesRead,
  onAdpCodeUpdated,
}: TimecardDetailTopbarProps) => {
  const missedShiftDeductVisible = useSelector(
    (state: RootState) => state.missedShiftCalculation.missedShiftDeductVisible
  );

  const startDate = moment(jobAssignment.jobAssignmentStartDate).format("MM/DD/YYYY");
  const endDate = moment(jobAssignment.jobAssignmentEndDate).format("MM/DD/YYYY");
  const [jobAssignmentNotesCount, setJobAssignmentNotesCount] = useState(
    jobAssignment?.jobAssignmentNotes.length ?? 0
  );

  const { canOpenTimecardAutomatically } = usePermissions();

  const { iconColor, showTooltip, tooltipText } = useHighlightIcon(jobAssignment);

  const showEditAdpCode =
    jobAssignment.status !== TimesheetStatus.APPROVED &&
    jobAssignment.status !== TimesheetStatus.BILLING;

  useEffect(() => {
    if (
      shouldOpenPDF(jobAssignment.status) &&
      canOpenTimecardAutomatically &&
      jobAssignment.timecardUploadId
    ) {
      PayrollApi.openTimecardFile(jobAssignment.timecardUploadId);
    }
  }, []);

  return (
    <div id="timecard-topbar" className="timecard-detail-topbar">
      <Avatar className="timecard-detail-topbar-avatar">
        {jobAssignment.candidateFullName
          .split(" ")
          .map((n: any) => n[0])
          .join("")}
      </Avatar>
      <div className="timecard-detail-topbar-details">
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}>
          <p className="timecard-detail-topbar-details-fullname">
            {jobAssignment.candidateFullName}
          </p>
          <UserNotifications
            candidateFullName={jobAssignment.candidateFullName}
            hasUnreadMessages={jobAssignment.hasUnreadMessages}
            onMessagesRead={onMessagesRead}
            timesheetId={jobAssignment.id}
          />
        </Box>
        <Box display={"flex"} gap={1} alignItems={"flex-end"}>
          <p className="timecard-detail-topbar-details-ssn" style={{ margin: 0 }}>
            Id: {jobAssignment.candidateSSN}
            {" - Assignment Period: "}
            {startDate + " - " + endDate}
          </p>
          <CanRenderChildren permissionName="canManageAdpCode" featureFlagName="isAdpCodeOn">
            <AdpCodeInput
              jobAssignment={jobAssignment}
              onAdpCodeUpdated={onAdpCodeUpdated}
              readonly={!showEditAdpCode}
            />
          </CanRenderChildren>
        </Box>
        <p className="timecard-detail-topbar-details-location">
          {jobAssignment.clientName + " - " + jobAssignment.clientLocation}
        </p>
        {(missedShiftDeductVisible || isReadOnly) && (
          <p className="timecard-detail-topbar-details-missed-shift-data">
            {"Number of Shifts: " +
              (jobAssignment.numberOfShifts ?? "--") +
              ", Hours per Shift: " +
              (jobAssignment.scheduledHours && jobAssignment.numberOfShifts
                ? (parseFloat(jobAssignment.scheduledHours) / jobAssignment.numberOfShifts).toFixed(
                    2
                  )
                : "--") +
              ", Scheduled Hours: " +
              (jobAssignment.scheduledHours ?? "--")}
          </p>
        )}
        {jobAssignment.workWeek !== null && jobAssignment.workWeek !== 0 && (
          <p className="timecard-detail-topbar-work-week">
            <span style={{ background: "#ffeb3b" }}>
              Alternate work week starting: {Enums.WorkWeek[jobAssignment.workWeek as never]}
            </span>
          </p>
        )}

        <TimecardDetailTopbarCallOffs
          showInfo={true}
          hospitalCallOffs={jobAssignment.jobAssignmentCallOffHospital}
          otherCallOffs={jobAssignment.jobAssignmentCallOffOther}
          sickCallOffs={jobAssignment.jobAssignmentCallOffSick}
          personalCallOffs={
            jobAssignment.jobAssignmentCallOffPersonal
          }></TimecardDetailTopbarCallOffs>

        <Accordion style={{ overflow: "auto", marginTop: "10px", marginBottom: "10px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center">
              <Typography>Additional Job Assignment Details</Typography>
              <Box className="timecard-tab-title-badge">
                <Typography fontSize={10}>{jobAssignmentNotesCount}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block", paddingBottom: 0, paddingTop: 0 }}>
            <div>
              <HeaderNotesList
                jobAssignmentId={jobAssignment.jobId}
                assignmentsFetched={(count: number) => setJobAssignmentNotesCount(count)}
                isReadOnly={isReadOnly}
              />
              <CanRenderChildren permissionName="canSeeConfirmationPageFile">
                <ConfirmationPageFile
                  jobId={jobAssignment.id}
                  confirmationPageUploadId={jobAssignment.confirmationPageUploadId}
                  onFileUploaded={onConfirmationPageFileUploaded}
                  uploading={
                    jobAssignment.confirmationPageUploading &
                    Enums.JobAssignmentFileTypes.CONFIRMATION_PAGE
                  }
                  isReadOnly={isReadOnly}
                />
              </CanRenderChildren>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {displayIcons && (
        <div className="timecard-tile-fileicons time-card-topbar">
          <SignatureIcon
            signatureStatus={jobAssignment.managerSignatureStatus}
            timesheetId={jobAssignment.id}
            hassEditRequest={jobAssignment.hasEditRequest}
            refreshData={fetchAssignments}
            facilityId={jobAssignment.clientId}
          />

          <TimecardFileIcon
            timecardUploadId={jobAssignment.timecardUploadId}
            uploadSource={jobAssignment.timecardUploadSource}
          />

          <FacilityReportAttachedIcon
            timesheetId={jobAssignment.id}
            filled={!!jobAssignment.facilityReportUploadId}
          />

          <TimecardIsLateIcon isLate={jobAssignment.isLate} />

          <HightlightIcon
            showTooltip={showTooltip}
            iconColor={iconColor}
            tooltipText={tooltipText}></HightlightIcon>

          <FileFordwardedIcon
            forwardingStatus={jobAssignment.forwardingStatus}
            timesheetId={jobAssignment.id}
            icon="forward_to_inbox"
          />

          <TimeCardAdjusmentsIcon
            isAdjustment={jobAssignment.isAdjustment}
            hasAdjustment={jobAssignment.hasAdjustment}
            dtHoursAdjusted={jobAssignment.dtHoursAdjusted}
          />
        </div>
      )}
    </div>
  );
};

export default TimecardDetailTopbar;
