import { combineReducers } from "redux";
import history from "./historyTableReducer";
import featureFlags from "./featureFlagsReducer";
import permissions from "./permissionsReducer";
import missedShiftCalculation from "./missedShiftCalculationReducer";
import { expenseTableReducer as expensesState } from "./expenseTableReducer";
import profile from "./profileTableReducer";
import header from "./headerReducer";
import { advancedSearchReducer as advancedSearch } from "./advancedSearchReducer";
import appSettings from "./appSettingsReducer";
import referrals from "./referralsTableReducer";
import { dtUsersTableReducer as dtusers } from "./usersTableReducer";
import { bppUsersTableReducer as bppUsers } from "./bppUsersTableReducer";
import { dtUsersEmailsTableReducer as dtUsersEmails } from "./dtUsersEmailsTableReducer";
import { dtTimeEntryPreviewReducer as dtTimeEntryPreview } from "./dtTimeEntryPreviewReducer";

const rootReducer = combineReducers({
  history,
  featureFlags,
  permissions,
  missedShiftCalculation,
  expensesState,
  profile,
  header,
  advancedSearch,
  appSettings,
  referrals,
  dtusers,
  bppUsers,
  dtUsersEmails,
  dtTimeEntryPreview,
});

export default rootReducer;
